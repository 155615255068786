import { DateTime } from 'luxon'

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export const SERVER_DATE_FORMAT = 'yyyy-MM-dd'
export const JS_DATE = 'JS_DATE'
export const SERVER_DATE = 'SERVER_DATE'

export const getFormattedDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return null
  }

  if (DateTime.fromISO(date).isValid) {
    return DateTime.fromISO(date).toFormat(format)
  }
  if (DateTime.fromJSDate(date).isValid) {
    return DateTime.fromJSDate(date).toFormat(format)
  }
  if (DateTime.fromFormat(date, SERVER_DATE_FORMAT).isValid) {
    return DateTime.fromFormat(date, SERVER_DATE_FORMAT).toFormat(format)
  }
  return date
}

export const TODAY_JS = DateTime.now().toJSDate()

export const isLuxonDateTime = (value) => {
  if (!value) {
    return false
  }
  return value instanceof DateTime && value.isValid
}

export const getLuxonDate = (date, type, rollbar) => {
  if (isLuxonDateTime(date)) {
    return
  }
  try {
    switch (type) {
      case JS_DATE:
        return DateTime.fromJSDate(date)
      case SERVER_DATE:
        return DateTime.fromFormat(date, SERVER_DATE_FORMAT)
    }
  } catch (error) {
    rollbar.error(error)
    return null
  }
}
export const getDifDays = (dateFrom, dateTo) => dateFrom.diff(dateTo, ['days']).toObject().days

const AUTOPAY_TIME = 17

const getShiftedAtopayDateTime = (dateTime, vendorTimeZone) => {
  const { day, month, year } = dateTime
  return DateTime.fromObject({ year, month, day, hour: AUTOPAY_TIME }, { zone: vendorTimeZone })
}
export const getNextAutopayDate = (dayOfPayment, vendorTimeZone = 'America/New_York') => {
  const current = DateTime.now()
  const { day, month, year } = current
  if (dayOfPayment === 'last_day_of_month') {
    const newDate = getShiftedAtopayDateTime(
      DateTime.fromObject({ year, month, day }).endOf('month'),
      vendorTimeZone,
    )
    if (newDate.toUnixInteger() > current.toUnixInteger()) {
      return newDate.toISO()
    } else {
      return getShiftedAtopayDateTime(
        DateTime.fromObject({ year, month, day: dayOfPayment }).plus({ months: 1 }).endOf('month'),
        vendorTimeZone,
      ).toISO()
    }
  } else {
    const newDate = getShiftedAtopayDateTime(
      DateTime.fromObject({ year, month, day: dayOfPayment }),
      vendorTimeZone,
    )
    if (newDate.toUnixInteger() > current.toUnixInteger()) {
      return newDate.toISO()
    } else {
      return getShiftedAtopayDateTime(newDate.plus({ months: 1 }), vendorTimeZone).toISO()
    }
  }
}
export const convertTimeZone = (vendorTimeZone) => {
  const current = DateTime.now()
  const { day, month, year } = current
  return DateTime.fromObject({ year, month, day }, { zone: vendorTimeZone }).toFormat('ZZZZ')
}
